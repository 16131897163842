export const colorPalette = {
  black: '#0E0E1B',
  blackHover: '#3E3E49',

  // New Color Palette
  vulcan: '#0E0E1B',
  tuna: '#3E3E49',
  comet: '#53536A',
  stormGrey: '#757588',
  bisonHide: '#C7B2AB',
  coldTurkey: '#D2C1BC',
  concrete: '#F3F3F3',
  snow: '#F9F9F9',
  white: '#FFF',
  error: 'red',

  // Old Color Palette
  grey: '#7B8D9D'
}
