// Packages
import React from 'react'
import { useField } from 'formik'

// Common
import { WithFormField } from '@common/types/Forms'
import { WithClassName } from '@common/types/Utilities'

// Svgs
import SimpleDownArrow from '@static/svgs/common/arrows/arrow-simple-down.inline.svg'

// Style
import '../styles/SelectField.css'

export interface SelectOption {
  name: string
  value: string
  privacyPolicy?: boolean
}

interface Props extends WithClassName, WithFormField {
  options: SelectOption[]
  placeholder?: string
  showError?: boolean
}

const SelectField = ({
  className,
  placeholder,
  label,
  options,
  showError,
  ...props
}: Props) => {
  const [field, { error, touched }] = useField(props)
  return (
    <div className={className}>
      <label>
        {label ? (
          <span className="text-tag block text-black mb-3">{label}</span>
        ) : null}
        <div className="SelectField-container">
          <SimpleDownArrow className="SelectField-arrow" />
          <select
            {...field}
            className="SelectField text-caption w-full"
            placeholder={placeholder}
          >
            {options.map((option: SelectOption) => {
              return (
                <option key={option.name} value={option.value}>
                  {option.name}
                </option>
              )
            })}
          </select>
        </div>
      </label>
      {touched && error && showError ? (
        <div className="error">{error}</div>
      ) : null}
    </div>
  )
}

export default SelectField
